<template>
  <div class="row">
    <modal :adaptive="true" height="auto" name="open_to_settings_table" width="300">
      <div class="row mx-0 py-7">
        <div class="col-12 row mx-0 py-2 navy-blue-border-bottom-1px text-xl font-weight-bold">
          <div class="col-6 text-center">{{ $t('general.colum_name') }}</div>
          <div class="col-6 text-center">{{ $t('general.add') }}</div>
        </div>
        <div class="col-12  mx-0 py-2 text-xl">
          <draggable v-model="fieldsToViewEdit" v-bind="dragOptions" :move="onMove" class="list-group py-4" tag="ul"
            @end="isDragging=false" @start="isDragging=true">
            <transition-group :name="'flip-list'" type="transition">
              <div v-for="element in fieldsToViewEdit" :key="element.sort"
                class="col-12 list-group-item w-300px d-flex justify-content-between ">
                <span class="col-6"> {{ $t(element.name) }}</span>

                <span class="col-6 d-flex justify-content-center">
                  <input v-model="element.type_status" style="font-size: 10px " type="checkbox"></span>
              </div>
            </transition-group>
          </draggable>
          <button class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeModal">
            {{ $t('esc.new_send_close').toUpperCase() }}
          </button>
          <button class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer"
            @click="onSubmitToSaveSettingTable">{{
            $t('esc.new_send')
            }}
          </button>
        </div>

      </div>
    </modal>
    <div class="col-md-12">
      <form @submit="filterResult()">
        <div id="filter-card" class="row py-3 px-4" v-if="filters">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-xl-6 col-lg-4 col-md-4 col-sm-12 my-2 row px-0 mx-0">
                <input-filter :helper-text="$t('general.search')" :model.sync="filters.search"
                  :placeholder="$t('general.search_filter')" title="general.search"></input-filter>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-4 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter :helper-text="$t('general.start_date')" :model.sync="filters.start_date"
                    name="start_time" title="general.start_date">
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter :helper-text="$t('general.end_date')" :model.sync="filters.end_date" name="end_date"
                    title="general.end_date">
                  </date-filter>
                </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 my-lg-2 my-2 my-sm-0 row mx-0">
                <div class="col-md-12 my-md-0">
                  <select-filter :helper-text="$t('sample.status_by_filter')" title="general.status_filter"
                    :item-per-row="1" :max="1"  :not-list="true" width="200px"
                    :model.sync="filters.model_house_status_id" :values="modelHouseStatues"></select-filter>
                </div>
              </div>
              <div
                class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button type="button" class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult()">{{
                    $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-md-12">
      <dashboard-box :title="$t('waybill.waybill_list')">
        <template v-slot:preview>
          <div class="d-flex justify-content-center items-center">
            <!--            <div class="w-60px h-60px">
                        </div>-->
            <!--            <top-waybill-nav
                            style="flex-grow: 1;"
                            :model.sync="currentModelHouseType"
                            :data="storeData"
                        ></top-waybill-nav>-->
            <div class="fixed" style="position: fixed !important; top: 230px; right: 70px;">
              <div class="w-60px h-60px" @click="openPopToSettingTable">
                <span v-html="getIconByKey('icons.waybill.edit', {
                  class: 'w-40px h-40px d-inline-block object-fill cursor-pointer'
                })">
                </span>
              </div>
            </div>

          </div>
          <data-table :bordered="false" :borderless="true" :fields.sync="tableFields" :hover="false"
            :items="formatModelHouse" :no-border-collapse="true" :outlined="false" :page.sync="page"
            :perPage.sync="perPage" :striped="false" tableVariant="''" :infiniteId="infiniteId" @hitBottom="onHitBottom"
            @rowClicked="onRowClicked"></data-table>
        </template>
      </dashboard-box>
    </div>
    <div class="fixed" style="bottom: 20px; right: 40px;" v-if="isUserGranted('ModelHouse', ['setTask'], false)">
      <div class="w-60px h-60px" @click="$router.push({name: 'model_house.entry'})">
        <span v-html="getIconByKey('icons.waybill.waybill_entry', {
          class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
        })">
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import SelectFilter from "@/assets/components/filters/SelectFilter";
import InputFilter from "@/assets/components/filters/InputFilter";
import DataTable from "@/assets/components/dataTable/DataTable";
import DashboardBox from "@/assets/components/DashboardBox";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import DateFilter from "@/assets/components/filters/DateFilter";

import moment from "moment";
import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import {MODEL_HOUSE_INDEX_START_DATE_STORAGE_NAME, MODEL_HOUSE_INDEX_END_DATE_STORAGE_NAME} from "@/core/storage/storage-names"

import {
  GET_ITEMS,
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  UPDATE_ITEM_BY_ID,
  FILTER,
  SET_FILTER,
  ITEMS
} from "@/core/services/store/modelhouse/modelhouse.module";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import * as _ from "lodash";
import draggable from 'vuedraggable'


export default {
  name: "ModelHouseIndex",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    draggable
  },
  data() {
    return {
      infiniteId: 1,
      editable: true,
      isDragging: false,
      delayedDragging: false,
      fieldsToViewEdit: [],
      page: 1,
      perPage: 25,
      search: null,
      startDate: moment().subtract(30, 'd'),
      endDate: moment().add(1, 'd'),
      downloading: false,
      paginate: [],
      currentModelHouseType: 1,
      modelTableItem: [
        {
          user_id: null,
          model_name: null,
          table_fields: {},
        }
      ],
      modelHouseStatues: [],
      storeData: [],
      storeUrl: 'api/stores',
      modelHouseUrl: 'api/model-house',
    };
  },
  methods: {
    ...mapMutations({
      'setFilter': SET_FILTER,
      'loading': LOADING,
    }),
    async onHitBottom($state = null) {
      
      if (this.loading) {
        return;
      }
      
      let self = this;

      let filters = _.cloneDeep(this.filters);
      this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
      await this.$store.dispatch(HANDLE_INFINITE_SCROLL, {
        url: this.modelHouseUrl,
        filters: filters,
        '$state': $state,
      }).then(result => {
        if (result.status) {
          if ($state) {
            $state.loaded();
          }
        } else {
          if ('end' in result) {
            if ($state) {
              $state.complete();
            }
          }
        }
      })
    },
    filterResult() {
      localStorage.setItem(MODEL_HOUSE_INDEX_START_DATE_STORAGE_NAME, moment(this.filters.start_date));
      localStorage.setItem(MODEL_HOUSE_INDEX_END_DATE_STORAGE_NAME, moment(this.filters.end_date));
      let self = this;
      self.page = 1;
      self.filters.page = 1;
      self.infiniteId++
      self.onHitBottom(null);
    },
    getTableItem() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/model_table',
        filters: {
          model_name: 'model_house'
        },
        acceptPromise: true,
        showLoading: false,
      }).then(result => {
        if (result.status) {
          for (let i = 0; i < result.data.length; i++) {
            self.modelTableItem = result.data;
            self.modelTableItem[i].table_fields = JSON.parse(result.data[i].table_fields)
          }
          for (const [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
            self.fieldsToViewEdit.push({
              'name': this.$t(value.name),
              'sort': value.sort,
              'type': value.type,
              'type_status': value.status,
              'key': key,
            })
            self.fieldsToViewEdit = _.sortBy(self.fieldsToViewEdit, ['sort']);
          }
        }
      });
    },
    orderList() {
      this.modelTableItem[0].table_fields = this.modelTableItem[0].table_fields.sort((one, two) => {
        return one.sort - two.sort;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.status) && !draggedElement.status
      );
    },
    openPopToSettingTable() {
      this.$modal.show('open_to_settings_table')
    },
    closeModal() {
      this.$modal.hide('open_to_settings_table');
    },
    onSubmitToSaveSettingTable() {
      let self = this;
      let json = {};
      for (let i = 0; i < this.fieldsToViewEdit.length; i++) {
        json[this.fieldsToViewEdit[i].key] = {
          name: this.fieldsToViewEdit[i].name,
          type: this.fieldsToViewEdit[i].type,
          status: this.fieldsToViewEdit[i].type_status,
          sort: i,
        }
      }
      let contents = {
        model_name: 'model_house',
        table_fields: json,
      }
      let payload = {
        url: 'api/model_table/' + this.modelTableItem[0].id,
        id: this.id,
        contents: contents,
      }
      this.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then(result => {
        if (result.status) {
          this.$modal.hide('open_to_settings_table');
          self.sweetAlertSuccess(self.$t('general.successfully_created'));
          this.fieldsToViewEdit = [];
          this.getTableItem();

        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
      });
    },
    resetFilters() {
      this.setFilter({
        start_date: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
        status: null,
        model_house_status_id:null,
        search: ""
      })
    },
    onRowClicked(item, index, event) {
      if (this.isUserGranted('ModelHouse', ['view'])) {
        this.$router.push({
          name: 'model_house.task.action',
          params: {
            id: this.modelHouses.data[index].sample_id,
            model_house_task_status: this.modelHouses.data[index].status_id,
            model_house_id: this.modelHouses.data[index].id
          }
        });
      }
    }
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("model_house.model_house") }
    ]);

    if (!this.filter) {
        this.resetFilters();
      }

    this.filters.end_date = localStorage.hasOwnProperty(MODEL_HOUSE_INDEX_END_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(MODEL_HOUSE_INDEX_END_DATE_STORAGE_NAME))
      : moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(MODEL_HOUSE_INDEX_START_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(MODEL_HOUSE_INDEX_START_DATE_STORAGE_NAME))
      : moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);

    this.$store.dispatch(GET_ITEMS, {
      url: 'api/statuses',
      filters: {
        type_id: 3
      },
      acceptPromise: true,
      showLoading: false,
    }).then(result => {
      if (result.status) {
        this.modelHouseStatues = self.convertArrayToObjectByKey(result.data, 'id', 'translations.0.name')
      }
    });

    if (this.isUserGranted('ModelHouse', ['viewAny'])) {
      this.$store.dispatch(GET_ITEMS, {
        url: this.storeUrl,
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          self.storeData = result.data.reverse();
        }
      });
      this.getTableItem();

      
      setTimeout(this.onHitBottom, 500);
    }
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER,
      modelHouses: ITEMS
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    tableFields() {
      let fields = [];
      let self = this;
      for (const [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
        if (value.status === true) {
          fields.push({
            'key': key,
            'value': this.filter && this.filter.hasOwnProperty(key) ? this.filter[key] : '',
            'label': this.$t(value.name),
            'sortable': value.status,
            'sort': value.sort,
            'class': "text-center border border-top-0 border-left-0",
            'tdClass': "cursor-pointer",
          })
        }
      }
      return _.sortBy(fields, ['sort']);
    },
    formatModelHouse() {
      if (!this.modelHouses || this.modelHouses.data === null || this.modelHouses.data === undefined) return [];
      let temp = [];
      for (let i = 0; i < this.modelHouses.data.length; i++) {
        let tempCurrent = this.modelHouses.data[i];
        let status = tempCurrent.status?tempCurrent.status.translations[0]:-1;
        temp.push({
          'id': tempCurrent.id,
          'image': tempCurrent.sample.image,
          'date': moment(tempCurrent.process_date, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT),
          'order_no': tempCurrent.sample ? tempCurrent.sample.collection_number : '-',
          'model_house_action_type': tempCurrent.model_house_action_type,
          'product_name': tempCurrent.product_name,
          'model_house_status': tempCurrent.status_id?tempCurrent.status_id:null,
          'model_house_status_detail': status,
        })
      }
      return temp;
    }
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
